<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style lang="scss">
/* 滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 4px;
  padding-bottom: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 4px;
}
ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
span,
div,
label {
  font-family: Microsoft YaHei;
  font-weight: 400;
}
body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
p {
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
</style>
