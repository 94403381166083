import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router =  new Router({
  routes: [
    {
      path: '/',
      name: 'layout',
      meta: { title: '哈密市非物质文化遗产数字化教学平台' },
      component: () => import('@/layout/layout.vue')
    },
    {
      path: '/regist',
      name: 'regist',
      meta: { title: '注册-哈密市非物质文化遗产数字化教学平台' },
      component: () => import('@/layout/regist.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: { title: '登录-哈密市非物质文化遗产数字化教学平台' },
      component: () => import('@/layout/login.vue')
    },
    {
      path: '/index',
      redirect: '/index/ydkt',
      meta: { title: '哈密市非物质文化遗产数字化教学平台' },
      component: () => import('@/views/index/index.vue'),
      children: [
        {
          path: 'info',
          name: 'index-info',
          meta: { title: '' },
          component: () => import('@/views/info.vue')
        },
        {
          path: 'ydkt',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/ydkt/index.vue')
        },
        {
          path: 'ydkt/detail',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/ydkt/detail.vue')
        },
        {
          path: 'ydkt/study',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/ydkt/study.vue')
        },
        {
          path: 'js',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/js/index.vue')
        },
        {
          path: 'js/home',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/js/home.vue')
        },
        {
          path: 'zxhd',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/zxhd/index.vue')
        },
        {
          path: 'wjdc',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/wjdc/index.vue')
        },
        {
          path: 'wjdc/survey',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/wjdc/survey.vue')
        },
        {
          path: 'wjdc/result',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/wjdc/result.vue')
        },
        {
          path: 'search',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/search/index.vue')
        },
        {
          path: 'hyzx',
          redirect: 'hyzx/info',
          meta: { title: '哈密市非物质文化遗产数字化教学平台' },
          component: () => import('@/views/hyzx/index.vue'),
          children: [
            {
              path: 'info',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/info.vue')
            },
            {
              path: 'field',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/field.vue')
            },
            {
              path: 'history',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/history.vue')
            },
            {
              path: 'collect',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/collect.vue')
            },
            {
              path: 'commont',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/commont.vue')
            },
            {
              path: 'attention',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/attention.vue')
            },
            {
              path: 'creation',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/creation.vue')
            },
            {
              path: 'notify',
              meta: { title: '哈密市非物质文化遗产数字化教学平台' },
              component: () => import('@/views/hyzx/notify.vue')
            },
          ]
        },
      ]
    }
  ]
})

// 添加导航守卫
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
