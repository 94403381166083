<template>
  <div class="ICH-base-pagination">
    <el-pagination
      background
      layout="pager,next"
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      next-text="下一页"
      @current-change="changePage"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: 'base-pagination',
  props: {
    pagerCount: {
      type: Number,
      default: 3
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {

    }
  },
  methods: {
    changePage(val) {
      this.$emit('changePage', val)
    },
  }
}
</script>
<style lang='scss' scoped>
@import '@/assets/vars.scss';
.ICH-base-pagination {
  ::v-deep .el-pagination {
    li.number,
    li.more {
      width: 56px;
      height: 47px;
      background: #fbefd8;
      border-radius: 3px;
      text-align: center;
      line-height: 47px;
      font-size: 14px;
      color: #cc9933;
      cursor: pointer;
      margin-right: 13px;
      &.active {
        background: $mainColor;
        color: #fff;
      }

      &:hover {
        background: $mainColor;
        color: #fff;
      }
    }
    .btn-next {
      width: 90px;
      height: 47px;
      line-height: 47px;
      background: #fbefd8;
      border-radius: 3px;
      text-align: center;
      font-size: 14px;
      color: #cc9933;
      cursor: pointer;
      &:hover {
        background: $mainColor;
        color: #fff;
      }
      & > span {
        line-height: 47px;
      }
    }
  }
}
</style>
